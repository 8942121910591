import React from "react";
import "./Course.css";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";



function Postgraduate() {
  return (
    <div className="course-page">
      <Header/>
      <header className="height-75">
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
          <h1 className="text-center fw-semibold">Courses</h1>
        </div>
      </header>

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
            <h2 className="mb-4 mb-lg-5">POST GRADUATE (P.G.)</h2>
            
            <h4>Course Overview</h4>

            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
            A postgraduate degree is a student who has successfully completed an undergraduate degree level course at a college or university and is undertaking further study at a more advanced level. The course content is designed to build on the knowledge obtained at undergraduate degree level. Often the course content is more practical in nature to better prepare the student with the skills they will need in the workplace. Completing postgraduate studies helps students further their career prospects and deepen their understanding of their area of study.
          
            
            </p>

            <h4>Subjects Available</h4>

            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
            <b>Master Of Arts</b><br/>
            HINDI, SOCIOLOGY, GEOGRAPHY, HOMESCIENCE
            </p>

            <h4>Duration:</h4>
            <p>It takes two years to complete an "Post Graduate" degree courses.</p>

            <h4>Eligibility</h4>
            <p>Candidates should have successfully completed their Bachelor's degree (preferably arts) of a minimum 3 or 4-year duration with its equivalent (10+2+3). The bachelor's or master's degree should be from a university which is recognized by the UGC (University Grants Commission) of India.</p>

            <h4>Admission Procedure</h4>
            <p>Admissions will be strictly on merit basis.Seats will be allotted on the merit and as per the direction of the Govt. and University as well as first come first served basis.</p>

            <h4>Course Fees:</h4>
            <p>The annual fee For M.A. is according to the Dr Ram Manohar Lohia Avadh University, Faizabad norms which can be deposited in two instalments.</p>

            <h4>Total Seat :</h4>
            <p>HINDI 80 seats<br/>
              SOCIOLOGY 80seats<br/>
              GEOGRAPHY 50seats<br/>
              HOMESCIENCE 50seats</p>

            
          </div>
          <div className="col-lg-6 d-flex justify-content-center">
            <img
              src="./img/history.jpg"
              className="img-fluid h-25 w-100"
              alt=""
            />
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Postgraduate;

import React from 'react'
import { Link } from 'react-router-dom'
import Header from '../../components/ChooseSection/Navbar/Header'
import Footer from '../../components/Footer/Footer'

function SwayamPrabha() {
  return (
    <div>
      <Header/>
    <div className='container my-5'>
      <div className="row">
        <div className='col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'>
            <h2 className='mb-4 mb-lg-5'>Swayam Prabha</h2>
            <h4 className='mb-2 mb-lg-3'>Swayam And Swayam Prabha Initiative By UGC</h4>
            <p style={{textAlign:'justify'}}>The SWAYAM PRABHA is Link group of 32 DTH channels devoted to telecasting of high-quality educational programmes on 24X7 basis using the GSAT-15 satellite. Every day, there will be new content for at least (4) hours which would be repeated 5 more times in Link day, allowing the students to choose the time of their convenience. The channels are uplinked from BISAG, Gandhinagar. The contents are provided by NPTEL, IITs, UGC, CEC, IGNOU, NCERT and NIOS. The INFLIBNET Centre maintains the web portal.

            </p>
           
            <h4 className='mb-4 mb-lg-5'>The DTH Channels shall cover the following:</h4>
            <p style={{textAlign:'justify'}}>
              <ul>
                <li>Higher Education: Curriculum-based course contents at post-graduate and under-graduate level covering diverse disciplines such as arts, science, commerce, performing arts, social sciences and humanities, engineering, technology, law, medicine, agriculture, etc. All courses would be certification-ready in their detailed offering through SWAYAM, the platform being developed for offering MOOCs courses.</li>
                <li>School education (9-12 levels): modules for teacher's training as well as teaching and learning aids for children of India to help them understand the subjects better and also help them in preparing for competitive examinations for admissions to professional degree programmes.</li>
                <li>Curriculum-based courses that can meet the needs of life-long learners of Indian citizens in India and abroad
                </li>
                <li>Assist students (class 11th & 12th) prepare for competitive exams.</li>
                
              </ul>
            </p>
            <h5 className='mb-4 mb-lg-5'>List of Swam Prabha Website</h5>
            <p style={{textAlign:'justify'}}>
              <ul>
                <li><Link to="https://swayam.gov.in/">https://swayam.gov.in/</Link></li>
                <li><Link to="http://epgp.inflibnet.ac.in/">http://epgp.inflibnet.ac.in/</Link></li>
                <li><Link to="https://www.swayamprabha.gov.in/">https://www.swayamprabha.gov.in/</Link></li>
                <li><Link to="http://ugcmoocs.inflibnet.ac.in/">http://ugcmoocs.inflibnet.ac.in/</Link></li>
                
              </ul>
            </p>
        </div>
            
           
        <div className='col-lg-6 d-flex justify-content-center'>
            <img src="./img/swayamprabha-acm.jpg" className='img-fluid h-50' alt="" />
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  )
}

export default SwayamPrabha
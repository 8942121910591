import React, { useState } from "react";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";
import "./Student.css";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Col, Row, Card, CardBody, Button } from "react-bootstrap";
import setting from "../../setting.json";

const schema = yup.object().shape({
    stu_name: yup.string().required("Fill value"),
    adm_no: yup.string().required("Fill value"),
    phone: yup.string().required("Fill value"),
    address: yup.string().required("Fill value"),
    message: yup.string().required("Fill value"),

});

function Feedback() {

    const { register, reset, handleSubmit, formState: { errors }, } = useForm({
        resolver: yupResolver(schema),
    });

    const [message, setMessage] = useState('');

    const saveFeedback = async (data) => {
        let sf = {
            "stu_name": data.stu_name,
            "adm_no": data.adm_no,
            "phone": data.phone,
            "address": data.address,
            "message": data.message
        }
        await fetch(setting.api + "api/saveFeedback", {
            body: JSON.stringify(sf),
            method: "post",
            mode: "cors",
            headers: {
                // 'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(u => {
                console.log(u);
                setMessage("Your Request submitted successfully.")
                reset({
                    stu_name: '',
                    adm_no: '',
                    phone: '',
                    address: '',
                    message: '',
                });

            });
    }

    return (

        <div className="student-page">
            <Header />
            <header className="height-75">
                <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
                    <h1 className="text-center fw-semibold">Feedback</h1>
                </div>
            </header>

            <div className="container my-5">
                <div className="row">
                    <div className="col-lg-6 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
                        <h2 className="mb-4 mb-lg-5">Feedback</h2>
                    </div>
                    <Row>
                        <Col lg={5}>
                            <Card >
                                <CardBody>
                                    <Row>
                                        <Col>

                                            <Form onSubmit={handleSubmit(saveFeedback)}>

                                                <Form.Group className="mb-3 " >
                                                    <Form.Control type="text" placeholder="Student Name" className="text"
                                                        {...register("stu_name")}
                                                    />
                                                    <p>{errors.stu_name?.message}</p>

                                                </Form.Group>

                                                <Form.Group className="mb-3 " >
                                                    <Form.Control type="text" placeholder="Admission No." className="text"
                                                        {...register("adm_no")}
                                                    />
                                                    {errors.adm_no && <p>{errors.adm_no.message}</p>}
                                                </Form.Group>

                                                <Form.Group className="mb-3 " >
                                                    <Form.Control type="text" placeholder="Student Phone" className="text"
                                                        {...register("phone")}
                                                    />
                                                    {errors.phone && <p>{errors.phone.message}</p>}
                                                </Form.Group>

                                                <Form.Group className="mb-3 " >
                                                    <Form.Control type="text" placeholder="Address" className="text"
                                                        {...register("address")}
                                                    />
                                                    {errors.address && <p>{errors.address.message}</p>}
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Control as="textarea" rows={3} placeholder="Enter Message"
                                                        {...register("message")}
                                                    />
                                                    {errors.message && <p>{errors.message.message}</p>}
                                                </Form.Group>

                                                <div className="form-group">
                                                    <Button type="submit" variant="success" style={{ float: 'right' }}>Submit</Button>
                                                </div>

                                            </Form>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </div>
            <Footer />
        </div>
    );
}

export default Feedback;

import React from 'react'
import Header from '../../components/ChooseSection/Navbar/Header'
import Footer from '../../components/Footer/Footer'

function ComputerCenter() {
  return (
    <div>
      <Header/>
    <div className='container my-5'>
      
      <div className="row">
        <div className='col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'>
            <h2 className='mb-4 mb-lg-5'>Computer Center</h2>
            <p style={{textAlign:'justify'}}>The Computer Centre has about 20 computers with 20Mbps Broadband internet facility at the center is used extensively by the college faculty and students for enhancing their teaching area-based knowledge as well as for other knowledge-based activities.
            <br/>
            Experience the new way of learning:Go Digital Make an India Digital
            </p>
           
            <h4 className='mb-4 mb-lg-5'>Computer Course available are:</h4>
            <p style={{textAlign:'justify'}}>
              <ul>
                <li>Basic Fundamentals of Computer.</li>
                <li>Basic Hardware and Software Knowledge</li>
                <li>MS Office (Word, Excel, Power Point)</li>
                <li>Internet, Online Services, HTML, Photoshops</li>
                <li>Modular Courses
                  <ul>
                  <li>Application Modular- Basic,DTP , Photoshop , Coral Draw , Web Designing</li>
                  <li>AccountingModular- Advance excel ,TallyERP ,Taxation , E-Banking , E -Accountings</li>
                  <li>Programming – C ,C++,Core java, Advance Java, Visual Basic.</li>
                  </ul>
                </li>
              </ul>
            </p>
        </div>
            
           
        <div className='col-lg-6 d-flex justify-content-center'>
            <img src="./img/nss.jpg" className='img-fluid h-75' alt="" />
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  )
}

export default ComputerCenter
import React from "react";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";
import "./About.css";



function History() {
  return (
    <div className="about-page">
      <Header/>
      <header className="height-75">
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
          <h1 className="text-center fw-semibold">College History</h1>
        </div>
      </header>

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
            <h2 className="mb-4 mb-lg-5">College History</h2>
            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
              वर्तमान परिवेश में सामाजिक आदर्शों के विघटन, नैतिक मूल्यों के
              क्षरण और उत्तरोत्तर सांस्कृतिक पाटन के कारण विश्व में भोगोन्मुखी
              संस्कृति का प्रचार-प्रसार बहुत तीव्र गति से हो रहा है | आज की युवा
              पीढ़ी कथित आधुनिक के नाम पर सांस्कृतिक मूल्यों का दमन करते हुये
              स्वेच्छाचारिता की तरफ अग्रसर होते हुये ननाविधि दुर्व्यसनों का
              शिकार हो रही है | इन ध्वंसात्मक स्थितियों एवं परिस्थियों के परिहार
              के लिए महाविद्यालय के संस्थापक जनपद के समर्पित समाजसेवी,
              लब्धप्रतिष्ठित चिकित्सक एवं पूर्व विधान परिषद सदस्य डा० ओम प्रकाश
              त्रिपाठी के मन में सदैव युवा पीढ़ी को ज्ञान, शक्ति, भक्ति, शिक्षा
              और आचरण में परसपरिक समन्जस्य का विधान करने वाली संस्था की स्थापना
              की कल्पना चला करती थी | इस कल्पना को मूर्त रूप देने में डा०
              त्रिपाठी को पूर्वान्चल के मालवीय शिक्षा के प्रचार-प्रसार में अपना
              सम्पूर्ण जीवन समर्पित करने वाले ब्रह्मलीन कर्मयोगी पंडित राम किशोर
              त्रिपाठी का पूर्ण सहयोग एवं मार्गदर्शन प्रपट होता रहा | प्रयाप्त
              विचार मंथन कर वाग्देवी सरस्वती के पावन पर्व वसंत पंचमी वर्ष 2008
              को महाविद्यालय की स्थापना का निर्णय लिया | डा0 त्रिपाठी ने अपने
              भगीरथ प्रयास से अर्थशास्त्र और राजनीति के अप्रतिम पुरोधा आचार्य
              चाणक्य के नीति वाक्य “विद्या लभते सर्व विद्या सर्वत्र पूज्यते” से
              प्रेरित होकर सर्वजन सुखाय सर्वजन हिताय हेतु “या विद्या सा
              विमुक्तये” की विराट कल्पना को साकार रूप प्रदान कर उच्च आदर्शों को
              स्थापित करने हेतु जनपद मुख्यालय से 20 की०मी० दूर बांदा-टांडा मार्ग
              पर आचार्य चाणक्य महाविद्यालय महमूदपुर सेमरी (सुलतानपुर) की स्थापना
              किए | सात कक्ष में कला संकाय के सात विषयों से प्रारम्भ किए गए इस
              महाविद्यालय में वर्तमान में कला, विज्ञान, शिक्षा तथा प्रशिक्षण चार
              संकाय संचालित है | कला संकाय में स्नातक स्तर पर 12 विषय तथा
              परास्नातक स्तर पर 04 विषयों के साथ बी०यससी०, बी०एड० और
              बी०टी०सी०(डी०यल०यड०) की कक्षाएं 45 कमरों के भवन में संचालित हो रही
              है |
            </p>

            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
              आचार्य चाणक्य महाविद्यालय कठोर अनुशासन, आदर्श शैक्षणिक गतिविधिओं,
              नकल विहीन परीक्षा और उत्तम परीक्षाफल के लिए अपनी अलग-अलग पहचान
              रखता है | दूरदर्शिता, समाजोत्थान, त्याग, समर्पण, कर्मशीलता एवं
              शिक्षा के प्रचार-प्रसार के लिए नित नयी योजनाओं का सृजन करने वाले
              डा० ओम प्रकाश त्रिपाठी के स्तुल्य प्रयासों की ही परिणति है की
              संस्था आज शैक्षिक विकास के साथ-साथ विद्यार्थियों को भारतीय
              संस्कृति, संस्कार और स्वस्थ जीवन मूल्यों के प्रति सचेत एवं
              निष्ठावान रहने के लिए संकल्पित करते हुये उनके रोजगारपरक शिक्षा तथा
              बहुमुखी विकास की दिशा में नित नए अवसर प्रदान कर रही है | यह
              महाविद्यालय अपने स्थापना काल से लेकर अद्दतन मानव धर्म के राजनीतिक,
              शिक्षा, अर्थ, एवं राजनीतिक क्षितिज के देदीप्यमान नक्षत्र आचार्य
              चाणक्य के अमृतोपम नीति वाक्य “विद्यया लभते सर्व विद्या सर्वत्र
              पूज्यते” को समाज में प्रतिविम्बित कर रहा है | 12 बसन्त का
              प्रत्यक्षदर्शी रहा यह महाविद्यालय अपने स्थापना काल से अद्यावधि
              विकास के पाठ पर अग्रसर है |
            </p>

            <p style={{ textAlign: "justify" }}> 
              <ul> <h4>उपलब्धियां:</h4>
                <li>सुव्यवस्थित वाचनालय एवं सुदृढ़ पुस्तकालय |</li>
                <li>सुसज्जित कम्पुटर कक्ष |</li>
                <li>अवस्थापनात्मक सुविधाओं से युक्त विशाल सभागार |</li>
                <li>आधुनिक उपकरणों से सुसज्जित 06 प्रयोगशालायें |</li>
                <li>छत्र/छात्राओं के लिए अलग-अलग कामन रूम |</li>
                <li>छत्र/छात्राओं के लिए अलग-अलग प्रशाधन कक्ष |</li>
                <li>पर्यावरणीय सम्वर्धन हेतु महाविद्यालय के समूर्ण परिसर का सुव्यवस्थित वानस्पतिक सौदर्यीकरण |</li>
                <li>जलीय जीव संरक्षक प्रेरणा प्रतीक स्वरूप उच्चकोटिका मत्स्य सरोवर |</li>
                <li>विस्तृत खेल मैदान |</li>
                <li>उच्च क्षमता उक्त इन्टरनेट टावर |</li>
                <li>उत्तम शुद्ध पेयजल के वाटर कूलर की सुबिधा |</li>
                <li>सम्पूर्ण महाविद्यालय परिसर उच्च तकनीकी क्षमतायुक्त सी०सी०टी०वी० कैमरा तथा वाई फाई आदि सुविधाओं से आच्छादित |</li>
                <li>दिव्यांग विद्यार्थियों के लिए सभी सुविधायें उपलब्ध है |</li>
              </ul>
            </p>
          </div>
          <div className="col-lg-6 d-flex justify-content-center">
            <img
              src="./img/history.jpg"
              className="img-fluid h-25 w-100"
              alt=""
            />
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default History;

import React, { useState } from "react";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";
import "./TalentSearch.css";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Col, Row, Card, CardBody, Button } from "react-bootstrap";
import setting from "../../setting.json";
import { Link } from "react-router-dom";

function TalentSearchExamEligibility() {

    return (

        <div className="student-page">
            <Header />
            <header className="height-75">
                <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
                    <h1 className="text-center fw-semibold"> Talent Search Exam 2025</h1>
                </div>
            </header>

            <div className="container my-5">
                <div className="row">
                    <div className="col-lg-12 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
                        <h2 className="mb-4 mb-lg-5" style={{ alignSelf: "center" }}>
                            Talent Search Exam Form 2025
                        </h2>

                    </div>
                    <Row>
                        <Col lg={9}>
                            <Card >
                                <CardBody>
                                    <ul>
                                        <li><b className="title1">Application Fees</b>: Online - Free Offline - Rs10.00/-</li>
                                        <li><b>Eligibility</b>: Intermediate with any stream class 11 and 12 students only.</li>
                                        <li><b>Admit Cards</b>: Admit cards will be available From 5th January 2025 on college website.
                                            And also, handover to participating college</li>
                                        <li><b>Exam Schedule</b>: 12th January 2025 at 11:00 AM to 01:00 PM at our College Premises.</li>
                                        <li><b>Declaration of Result</b>: 26 January 2025 12:00AM at our college website (www.acmcollege.org)</li>
                                        <li><b>Award Distribution Programme </b>:<del>02 February 2025</del> <ins>16 February 2025</ins> (Sunday) </li>
                                        <li><b>Syllabus</b>: Syllabus as per Matriculation Level. MCQ based question.</li>

                                    </ul>

                                    <ul className="list2">
                                        <li>General Hindi& English 20 Marks</li>
                                        <li>General Knowledge 20 Marks</li>
                                        <li>Mathematics & Reasoning 20 Marks</li>
                                        <li>Social Science 20 Marks</li>
                                        <li>General Science 20 Marks</li>
                                        <li><b>Total Marks 100 Marks</b></li>
                                        <li><b>Duration: 02 Hours</b></li>

                                    </ul>
                                    <p><b>Helpline Number for technical support : 6394230457</b></p>
                                    <Link to="/talent-search-result-2025"><Button variant="info">Result Now</Button></Link>
                                </CardBody >
                            </Card >
                        </Col >
                        <Col lg={3}>
                            <p>Important Links</p>
                            <ul>
                                <li><Link to="/talent-search-exam-from-2025">Talent Search Exam Form 2025</Link></li>
                                <li><Link to="/admit-card-2025">Talent Search Admit Card 2025</Link></li>
                                <li><Link to="/talent-search-result-2025">Talent Search Result 2025</Link></li>
                                <li><Link to="/know-your-registration">Know Your Registration 2025</Link></li>
                            </ul>



                        </Col>
                    </Row >

                </div >
            </div >
            <Footer />
        </div >
    );
}

export default TalentSearchExamEligibility;

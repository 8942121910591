import React from "react";
import Header from "../../components/ChooseSection/Navbar/Header";
import "./About.css";
import Footer from "../../components/Footer/Footer";





function Kulgeet() {
  return (
    <div className="about-page">
      <Header/>
      <header className="height-75">
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
          <h1 className="text-center fw-semibold">College Kulgeet</h1>
        </div>
      </header>

      <div className="container my-5">
        <div className="">
          
          <div className="col-lg-6 d-flex justify-content-center">
              <img
                src="./img/kulgeet.jpeg"
                className="img-fluid  "
                alt=""
              />
            </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Kulgeet;

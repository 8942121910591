import React from "react";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";
import "./Student.css";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Col, Row, Card, CardBody, Button } from "react-bootstrap";

const schema = yup.object().shape({
    uhi_number: yup.string().required("Please enter value"),
    admission: yup.string().required("Please enter value"),
    image: yup.string().required("Please enter value"),
});

function SamarthPortal() {

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),

    })
     
    return (

        <div className="student-page">
            <Header />
            <header className="height-75">
                <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
                    <h1 className="text-center fw-semibold">Samarth Portal Verifiation</h1>
                </div>
            </header>

            <div className="container my-5">
                <div className="row">
                    <div className="col-lg-6 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
                        <h2 className="mb-4 mb-lg-5">Samarth Portal Verification</h2>
                    </div>
                    <Row>
                        <Col lg={5}>
                            <Card >

                                <CardBody>
                                    <Row>
                                        <Col>

                                            <Form onSubmit={handleSubmit()}
                                            >
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>UHI Number</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="UHI No."
                                                        {...register('uhi_number')}
                                                    />
                                                    {errors.uhi_number && <p>{errors.uhi_number.message}</p>}
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Admission Number</Form.Label>
                                                    <Form.Control type="text" placeholder="Admission No." {...register('admission')} />
                                                    {errors.admission && <p>{errors.admission.message}</p>}
                                                </Form.Group>

                                                <Form.Group controlId="formFile" className="mb-3">
                                                    <Form.Label>Upload file </Form.Label>
                                                    <Form.Control type="file" {...register('image')} />
                                                    {errors.image && <p>{errors.image.message}</p>}
                                                </Form.Group>

                                                <Button type="submit" variant="success" style={{ float: 'right' }}>Submit</Button>
                                            </Form>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                </div>
            </div>
            <Footer />
        </div>
    );
}

export default SamarthPortal;

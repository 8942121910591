import React, { useState } from "react";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";
import "./TalentSearch.css";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Col, Row, Card, CardBody, Button } from "react-bootstrap";
import setting from "../../setting.json";
import schools from '../../constants/schools.json';
import { Link, useNavigate } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import AdmitCard from "./AdmitCard";

const schema = yup.object().shape({
    stu_name: yup.string().required("Please enter Name"),
    father_name: yup.string().required("Please enter Father Name"),
    dob: yup.string().required("Please enter Date of Birth"),
    gender: yup.string().required("Please enter Gender"),
    aadhar: yup.string(12, "Aadhar must be of 12 digit").required("Please enter Aadhar Number"),
    phone: yup.string(10, "Phone must be of 10 digit").required("Please enter Phone"),
    email: yup.string(),
    class: yup.string().required("Please enter Class"),
    stream: yup.string().required("Please enter Stream"),
    school: yup.string().required("Please enter School"),
    address: yup.string().required("Please enter Address"),
    photo: yup.mixed().test('required', "Please enter photo", (value) => { return value && value.length }),
    sign: yup.mixed().test('required', "Please enter signature", (value) => { return value && value.length }),
});

function TalentSearchExamFrom() {

    const [msg, setMsg] = useState();
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    const saveExamData = async (data) => {
        const photo = document.getElementById('photo').files;
        let photolist = Array.from(photo);
        const sign = document.getElementById('sign').files;
        let signlist = Array.from(sign);
        const fd = new FormData();
        fd.append("stu_name", data.stu_name);
        fd.append("father_name", data.father_name);
        fd.append("dob", data.dob);
        fd.append("gender", data.gender);
        fd.append("aadhar", data.aadhar);
        fd.append("phone", data.phone);
        fd.append("email", data.email);
        fd.append("class", data.class);
        fd.append("stream", data.stream);
        fd.append("school", data.school);
        fd.append("address", data.address);
        fd.append("photo", photolist[0]);
        fd.append("sign", signlist[0]);
        console.log(data);

        await fetch(setting.api + '/api/saveExamData', {
            body: fd,
            method: "post",
            mode: "cors",

        })
            .then(response => response.json())
            .then(u => {
                console.log(u);
                if (u.result == "success") {
                    navigate('/formSuccess/' + u.data);

                } else {
                    navigate('/formerror');
                }


            });
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (

        <div className="student-page">
            <Header />
            <header className="height-75">
                <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
                    <h1 className="text-center fw-semibold"> Talent Search Exam 2025</h1>
                </div>
            </header>

            <div className="container my-5">
                <div className="row">
                    <div className="col-lg-12 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
                        <h2 className="mb-4 mb-lg-5" style={{ alignSelf: "center" }}>
                            Talent Search Exam Form 2025
                        </h2>

                    </div>
                    <Row>
                        <Col lg={9}>
                            <Card >
                                <CardBody>
                                    <Form onSubmit={handleSubmit(saveExamData)}
                                    >
                                        <Row>
                                            <Col xs={12} md={4}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Student Name</Form.Label>
                                                    <Form.Control type="text"
                                                        placeholder="Name"
                                                        {...register('stu_name')}
                                                    />
                                                    {errors.stu_name && <p>{errors.stu_name.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Father Name</Form.Label>
                                                    <Form.Control type="text"
                                                        placeholder="Father name"
                                                        {...register('father_name')} />
                                                    {errors.father_name && <p>{errors.father_name.message}</p>}
                                                </Form.Group>
                                            </Col>

                                            <Col xs={12} md={4}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Date Of Birth</Form.Label>
                                                    <Form.Control type="date" placeholder="DOB"
                                                        {...register('dob')} />
                                                    {errors.dob && <p>{errors.dob.message}</p>}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={4}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Gender</Form.Label>
                                                    <Form.Select aria-label="Gender" {...register('gender')} >
                                                        <option value="">Select Gender</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                        <option value="other">Other</option>
                                                    </Form.Select>
                                                    {errors.gender && <p>{errors.gender.message}</p>}
                                                </Form.Group>
                                            </Col>

                                            <Col xs={12} md={4}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Aadhar Number</Form.Label>
                                                    <Form.Control type="number" placeholder="Aadhar No."
                                                        {...register('aadhar')} />
                                                    {errors.aadhar && <p>{errors.aadhar.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Mobile Number</Form.Label>
                                                    <Form.Control type="number" placeholder="Phone"
                                                        {...register('phone')} />
                                                    {errors.phone && <p>{errors.phone.message}</p>}
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12} md={4}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Class</Form.Label>
                                                    <Form.Select aria-label="Class" {...register('class')}>
                                                        <option value="">Select Class</option>
                                                        <option value="11">11th</option>
                                                        <option value="12">12th</option>
                                                    </Form.Select>
                                                    {errors.class && <p>{errors.class.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={4}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Stream</Form.Label>
                                                    <Form.Select aria-label="Class" {...register('stream')}>
                                                        <option value="">Select Stream</option>
                                                        <option value="arts">Arts</option>
                                                        <option value="commerce">Commerce</option>
                                                        <option value="science">Science</option>
                                                        <option value="agriculture">Agriculture</option>
                                                    </Form.Select>
                                                    {errors.stream && <p>{errors.stream.message}</p>}
                                                </Form.Group>
                                            </Col>


                                            <Col xs={12} md={4}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>School/College Name</Form.Label>
                                                    <Form.Select aria-label="Class" {...register('school')}>
                                                        <option value="">Select School</option>
                                                        {schools.map((rec) =>
                                                            <option value={rec.name}>{rec.name}</option>
                                                        )}
                                                    </Form.Select>

                                                    {errors.school && <p>{errors.school.message}</p>}
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type="email" placeholder="email"
                                                        {...register('email')} />

                                                </Form.Group>
                                            </Col>

                                            <Col xs={12} md={6}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Address</Form.Label>
                                                    <Form.Control type="text" placeholder="address"
                                                        {...register('address')} />
                                                    {errors.address && <p>{errors.address.message}</p>}
                                                </Form.Group>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Photo</Form.Label>
                                                    <Form.Control type="file" placeholder="Photo"
                                                        {...register('photo')} id="photo" />
                                                    {errors.photo && <p>{errors.photo.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col xs={12} md={6}>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                                    <Form.Label>Signature</Form.Label>
                                                    <Form.Control type="file" placeholder="Signature"
                                                        {...register('sign')} id="sign" />
                                                    {errors.sign && <p>{errors.sign.message}</p>}
                                                </Form.Group>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12}>
                                                <Button type="submit" variant="success" onClick={handleShow} style={{ float: 'right' }}>Submit</Button>
                                            </Col>
                                        </Row>

                                    </Form>

                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={3}>
                            <p>Important Links</p>
                            <ul>
                                <li><Link to="/talent-search-exam-from-2025">Talent Search Exam Form 2025</Link></li>
                                <li><Link to="/talent-search-result-2025">Talent Search Result 2025</Link></li>
                                <li><Link to="/admit-card-2025">Talent Search Admit Card 2025</Link></li>
                            </ul>



                        </Col>
                    </Row>

                </div>
            </div>
            <Footer />
        </div>
    );
}

export default TalentSearchExamFrom;

import React from 'react'
import "./Footer.css";
import { Link } from "react-router-dom";
import ncte from "../../images/ncte.png";
import digitalIndia from "../../images/digital-India.png";
import rml from "../../images/rml.png";
import swachta from "../../images/swachta.png";
import antiragging from '../../images/antiragging.png';

function Footer() {
  return (
    <>
      <footer style={{ backgroundColor: "rgb(9, 9, 137)" }}>
        <div className="container my-0 py-3">
          <div className="row d-flex justify-content-between align-items-center">
            <div className="col-md-2">
              <ul className="footer-social-icons list-unstyled d-flex justify-content-between mb-0">
                <Link to="/contact">
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm mb-5 mb-md-4"
                  >
                    Get In Touch
                  </button>
                </Link>

              </ul>
              <ul className="footer-social-icons list-unstyled d-flex justify-content-between">
                <a href="https://ncte.gov.in/Website/Index.aspx">
                  <li>
                    <img
                      src={ncte}
                      width="32"
                      height="32"
                      className="foot-img"
                    />
                  </li>
                </a>

                <a href="https://www.rmlau.ac.in/new/index.aspx">
                  <li>
                    <img src={rml} width="32" height="32" />
                  </li>
                </a>
                <a href="https://www.antiragging.in/">
                  <li>
                    <img src={antiragging} width="32" height="32" />
                  </li>
                </a>


              </ul>
            </div>
            <div className="col-md-3">

              <ul className="list-unstyled mb-0 text-light">
                <li>
                  <p
                    className="my-0 fw-semibold"
                    style={{ fontSize: "20px" }}
                  >
                    National Anti-Ragging Helpline

                  </p>
                </li>
                <li>
                  <p
                    className="my-0 fw-semibold"
                    style={{ fontSize: "14px" }}
                  >

                    Phone No -: 1800-180-5522

                  </p>
                </li>
                <li>
                  <p
                    className="my-0 fw-semibold"
                    style={{ fontSize: "14px" }}
                  >
                    Email -: helpline@antiragging.in
                  </p>
                </li>
                <li>
                  <p
                    className="my-0 fw-semibold"
                    style={{ fontSize: "14px" }}
                  >
                    &nbsp;
                  </p>
                </li>
              </ul>

            </div>
            <div className='col-md-3'>
              <ul className="footer-navigation list-unstyled mb-0">
                <Link to="/" className="text-decoration-none text-danger">
                  <li
                    className="text-uppercase fw-semibold"
                    style={{ fontSize: "14px" }}
                  >
                    Home
                  </li>
                </Link>
                <Link
                  to="/undergraduate"
                  className="text-decoration-none text-danger"
                >
                  <li
                    className="text-uppercase fw-semibold"
                    style={{ fontSize: "14px" }}
                  >
                    Undergraduate Courses
                  </li>
                </Link>
                <Link
                  to="/postgraduate"
                  className="text-decoration-none text-danger"
                >
                  <li
                    className="text-uppercase fw-semibold"
                    style={{ fontSize: "14px" }}
                  >
                    Postgraduate Courses
                  </li>
                </Link>
                <Link
                  to="/mission&vision"
                  className="text-decoration-none text-danger"
                >
                  <li
                    className="text-uppercase fw-semibold"
                    style={{ fontSize: "14px" }}
                  >
                    Our Vision
                  </li>
                </Link>
                <Link
                  to="/admission-procedure"
                  className="text-decoration-none text-danger"
                >
                  <li
                    className="text-uppercase fw-semibold"
                    style={{ fontSize: "14px" }}
                  >
                    Admission
                  </li>
                </Link>
                <Link
                  to="/contact"
                  className="text-decoration-none text-danger"
                >
                  <li
                    className="text-uppercase fw-semibold"
                    style={{ fontSize: "14px" }}
                  >
                    Contact
                  </li>
                </Link>
              </ul>
            </div>
            <div className="col-md-3">

              <ul className="list-unstyled mb-0 text-light">
                <li>
                  <p
                    className="my-0 fw-semibold"
                    style={{ fontSize: "14px" }}
                  >
                    Mehmoodpur Semari, Sultanpur, Uttar Pradesh-228142
                  </p>
                </li>
                <li>
                  <p
                    className="my-0 fw-semibold"
                    style={{ fontSize: "14px" }}
                  >
                    Phone Number - +919616666937
                  </p>
                </li>
                <li>
                  <p
                    className="my-0 fw-semibold"
                    style={{ fontSize: "14px" }}
                  >
                    Email - acmv2121@gmail.com
                  </p>
                </li>
              </ul>

            </div>
          </div>
        </div>
        <div
          className=""
          style={{
            backgroundColor: "rgb(9, 9, 137)",
            borderTop: "solid white 1px",
            color: "white",
          }}
        >
          <div className="container">
            <p className="p-3 m-0 text-center py-1">
              Designed by{" "}
              <a
                href="https://sparklogix.in/"
                target="_blank"
                style={{ textDecoration: "none ", color: "white" }}
              >
                Spark Logix
              </a>
            </p>
          </div>
        </div>
      </footer>
    </>

  );
}

export default Footer
import React from "react";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";

function CollegeCampus() {
  return (
    <div>
      <Header/>
      <div className="container my-5">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0">
            <h2 className="mb-4 mb-lg-5">College Campus</h2>
            <p style={{ textAlign: "justify" }}>
              A modern, well equipped infrastructure is provided to all courses
              to facilitate better learning. The college has refurbished lecture
              rooms and laboratories. This makes the teaching - learning process
              student-friendly and interactive. It has multimedia rooms, a
              swanky Auditorium, Laboratory, huge airy Library with a rich
              collection of books, journals and periodicals and a airy Sports
              room.
            </p>
          </div>

          <div className="col-lg-6 d-flex justify-content-center">
            <img
              src="./img/banner-acm.png"
              className="img-fluid h-100"
              alt=""
            />
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default CollegeCampus;

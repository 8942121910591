import React, { useState } from 'react';
import './Contact.css';
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";
import setting from "../../setting.json";
import { Form, Col, Row, Card, CardBody, Button } from "react-bootstrap";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  stu_name: yup.string().required("Fill value"),
  email: yup.string().required("Fill value"),
  message: yup.string().required("Fill value"),

});

function Contact() {

  const { register, reset, handleSubmit, formState: { errors }, } = useForm({
    resolver: yupResolver(schema),
  });

  const [message, setMessage] = useState('');

  const saveContactQuery = async (data) => {
    let sr = {
      "stu_name": data.stu_name,
      "email": data.email,
      "message": data.message
    }
    await fetch(setting.api + "api/saveContactQuery", {
      body: JSON.stringify(sr),
      method: "post",
      mode: "cors",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
    })
      .then(response => response.json())
      .then(u => {
        console.log(u);
        setMessage("Your Request has been submitted successfully.")
        reset({
          stu_name: '',
          email: '',
          message: '',
        });

      });
  }
  return (
    <>

      <div className="contact-page">
        <Header />
        <header className="height-75">
          <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
            <h1 className="text-center fw-semibold">Contact</h1>
          </div>
        </header>

        <div className="container my-5">
          <div className="row">
            <div className="col-lg-6 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
              <h2 className="mb-4 mb-lg-5">Contact</h2>
            </div>
            <Row>
              <Col lg={5}>
                <Card >

                  <CardBody>
                    <Row>
                      <Col>

                        <Form onSubmit={handleSubmit(saveContactQuery)}>

                          <Form.Group className="mb-3 " >
                            <Form.Control type="text" placeholder="User Name" className="text" {...register("stu_name")} />
                            {errors.stu_name && <p>{errors.stu_name.message}</p>}
                          </Form.Group>

                          <Form.Group className="mb-3 " >
                            <Form.Control type="text" placeholder="Email" className="text"
                              {...register("email")} />
                            {errors.email && <p>{errors.email.message}</p>}
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                            <Form.Control as="textarea" rows={3} placeholder="Enter Message"
                              {...register("message")} />
                            {errors.message && <p>{errors.message.message}</p>}
                          </Form.Group>

                          <div className="form-group">
                            <Button type="submit" variant="success" style={{ float: 'right' }}>Submit</Button>
                          </div>

                        </Form>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

          </div>
        </div>
        <Footer />
      </div>


    </>
  );
}

export default Contact;
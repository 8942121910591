import React, { useEffect, useState } from "react";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";
import "./News.css";
import setting from "../../setting.json";
import { Card, CardHeader, Button } from 'react-bootstrap';
import DoubleArrow from '@mui/icons-material/ArrowForward';
import pdfToText from 'react-pdftotext'

function News() {
    const [news, setNews] = useState();
    const [pdf, setPdf] = useState();

    const getAllNews = async () => {
        await fetch(setting.api + "api/getAllNews", {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(u => {
                setNews(u.data);
                // if (data.pdf == file) {
                //     setPdf(true);
                // } else {
                //     setPdf(false);
                // }
            });
    }

    // function extractText(event) {
    //     const file = event.target.files[0]
    //     pdfToText(file)
    //         .then(text => console.log(text))
    //         .catch(error => console.error("Failed to extract text from pdf"))
    // }

    useEffect(() => {
        getAllNews();
    }, []);

    return (

        <div className="student-page">
            <Header />
            <header className="height-75">
                <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
                    <h1 className="text-center fw-semibold">News</h1>
                </div>
            </header>

            <section className="teacher-section py-3 bg_img ">
                <div className="container">

                    <div >
                        <Card
                            // className="d-flex h-100"
                            style={{
                                border: "solid 2px #2f2f30",
                                backgroundColor: "floralwhite"
                            }}
                        >

                            <Card.Body>

                                <CardHeader style={{ backgroundColor: "lightgray", color: "black", textAlign: "center" }}><b>News & Notification</b></CardHeader>
                                {news && news.map((n) =>

                                    <p className='text-start' style={{ borderBottom: "solid 1px", marginTop: "7px" }}>
                                        <DoubleArrow />
                                        {n.description}
                                        <a href={n.description} target="_blank"><Button variant="danger" size="sm" style={{ padding: "unset", marginLeft: "4px" }}>new</Button></a>

                                    </p>

                                )}

                            </Card.Body>
                        </Card>
                    </div>

                </div>
            </section >
            <Footer />
        </div >
    );
}

export default News;

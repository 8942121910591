import React from 'react'
import Header from '../../components/ChooseSection/Navbar/Header';
import Footer from '../../components/Footer/Footer';


function Lab() {
  return (
    <div>
      <Header/>
    <div className='container my-5'>
      
            <div className="row">
                <div className='col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'>
                    <h2 className='mb-4 mb-lg-5'>Laboratories</h2>
                    <p>Our College Provides modern and sophisticated teaching facilities that are constantly upgraded. Each department at the institute boasts of an array of highly advanced laboratories that are sufficiently equipped with the latest instruments and apparatus. The aim is to provide students a conceptual as well as practical understanding of the subject through hands-on training. Our college allocates considerable resources on a regular basis in enhancing all its facilities to meet cutting edge, international standards.
                    <br/>
                     -PHYSICS LAB<br/>
                     -CHEMISTRY LAB<br/>
                     -BIOLOGY LAB<br/>
                     -ZOOLOGY<br/>
                     -BOTANY<br/>
                     -HOME SCIENCE LAB<br/>
                     -GEOGRAPHY LAB</p>
                   
                </div>
                <div className='col-lg-6 d-flex justify-content-center'>
                    <img src="./img/lab.jpg" className='img-fluid w-100 h-75' alt="" />
                </div>
            </div>
    </div>
    <Footer/>
    </div>
  )
}

export default Lab;
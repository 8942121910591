import React, { useState } from "react";
import "./TalentSearch.css";
import { Card, Form, Col, Row } from 'react-bootstrap';
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import certificateImage from '../../images/certificate.jpg';

import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({
  rollno: yup.string(),

});

function TalentSearchResult() {

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),

  })

  const [result, setResult] = useState(null);

  const getExamDataByRollno = async (data) => {

    await fetch("https://app.acmcollege.org/api/getExamDataByRollno?rollno=" + data.rollno, {
      method: "GET",
      // body: JSON.stringify(),
      mode: "cors",
      // headers: {
      //   "Content-Type": "application/json",
      // }
    })
      .then(response => response.json())
      .then(res => {
        setResult(res.data);
      })
  }

  return (
    <div className="talent-page">
      <Header />
      <header className="height-75">
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
          <h1 className="text-center fw-semibold">Talent Search Exam 2025</h1>
        </div>
      </header>

      <div className="container my-5">
        <Row>
          <Col md={7} className="col-lg-8 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
            <h2 className="mb-4 mb-lg-5" style={{ textAlign: "center" }}>
              <u>Talent Search Exam 2025</u>
            </h2>

            <Card
              className="h-100 w-100 shadow"
              style={{ backgroundColor: "aliceblue" }}
            >
              <Card.Body className="p-md-3" >
                <Card.Title style={{ fontSize: "16px", textAlign: "center" }}>
                  <h3>Download Your Certificate</h3>
                </Card.Title>
                <Card.Text style={{ fontSize: "12px", textAlign: "center" }}>
                  Enter your Roll No. to check your result.
                  <br />
                  <br />


                  <Form onSubmit={handleSubmit(getExamDataByRollno)}>
                    <div>
                      <input
                        type="text"
                        {...register("rollno")}
                        style={{
                          width: "60%",
                          height: "30px",
                          border: "solid black 1px",
                          borderRadius: "5px",
                        }}
                      />
                    </div>
                    <br />
                    <Button
                      type="submit"
                      className="btn btn-danger btn-sm mx-0 mx-sm-2 my-2 my-sm-0"
                      style={{ borderRadius: "10px" }}

                    >
                      Search
                    </Button>
                  </Form>
                  <br />
                  <br />
                </Card.Text>

                <Row
                  style={{
                    backgroundColor: "aliceblue",
                    borderRadius: "20px",
                  }}
                >
                  <Col xs={6} md={3}><b>Roll No. :</b></Col>
                  <Col xs={6} md={3}>{result && result.rollno}</Col>
                  <Col xs={6} md={3}> <b>Certificate No. :</b></Col>
                  <Col xs={6} md={3}>{result && result.certificate_no}</Col>
                  <Col xs={6} md={3}><b>Student Name :</b></Col>
                  <Col xs={6} md={3}>{result && result.stu_name.toUpperCase()}</Col>
                  <Col xs={6} md={3}><b>Father's Name :</b></Col>
                  <Col xs={6} md={3}>{result && result.father_name.toUpperCase()}</Col>

                  <Col xs={6} md={3}><b>Score :</b></Col>
                  <Col xs={6} md={3}>{result && result.marks}</Col>
                  <Col xs={12} md={6}></Col>
                  <Col xs={6} md={3}><b>School Name :</b></Col>
                  <Col xs={6} md={9}>{result && result.school.toUpperCase()}</Col>
                  <Col xs={6} md={3}><b>Prize :</b></Col>
                  <Col xs={6} md={9}>{result && result.remarks.toUpperCase()}</Col>
                  {/*   <p className="col-3 head">
                     <b>Result :</b>
                    </p>
                    <p className="col-3 detail">{result && result.result}</p>
                    <p className="col-3 head">
                      <b>Prize :</b>
                    </p>
                    <p className="col-3 detail">{result && result.prize}</p> */}

                </Row>
                <br />
                <p style={{ fontSize: "12px", textAlign: "center" }}>
                  {result && result.present == "1"
                    ? (
                      <Link
                        className="btn btn-danger btn-sm mx-0 mx-sm-2 my-2 my-sm-0 align-center"
                        to={`/certificate/${result && result.rollno}`}

                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-printer-fill"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1" />
                          <path d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1" />
                        </svg>
                      </Link>
                    ) : (
                      ""
                    )}
                </p>

              </Card.Body>
            </Card>
          </Col>
          <Col md={5} className="col-lg-4  justify-content-center">
            <br />
            <br />
            <p style={{ fontSize: "16px", textAlign: "center" }}>
              (Sample Certificate)
            </p>
            <img src={certificateImage} className="img-fluid w-100" alt="" />
          </Col>
        </Row>

      </div>
      <Footer />
    </div>
  );
}

export default TalentSearchResult;

import './App.css';
import { Link, Routes, Route } from 'react-router-dom';

import Home from './pages/Home/Home';
import Courses from './pages/Courses/Courses';
import SmartClass from './pages/Facilities/SmartClass';
import Lab from './pages/Facilities/Lab';
import Library from './pages/Facilities/Library';
import Sports from './pages/Facilities/Sports';
import Nss from './pages/Facilities/Nss';
import ComputerCenter from './pages/Facilities/ComputerCenter';
import CollegeCampus from './pages/Facilities/CollegeCampus';
import SwayamPrabha from './pages/Facilities/SwayamPrabha';
import Gallery from './pages/Gallery';

import SamarthPortal from './pages/Student/SamarthPortal';
import Feedback from './pages/Student/Feedback';
import Complaints from './pages/Student/Complaints';
import StudentData from './pages/Student/StudentData';
import Biomatric from './pages/Student/Biomatric';

import Faculty from './pages/Faculty/Faculty';
import Bed from './pages/Faculty/Bed';
import Deled from './pages/Faculty/Deled';

import Balancesheet from './pages/Download/Balancesheet';
import Balancsheet from './pages/Download/Balancsheet';
import Income from './pages/Download/Income';
import Incomeex from './pages/Download/Incomeex';
import Incomeexpenditure from './pages/Download/Incomeexpenditure';
import Payment from './pages/Download/Payment';


import History from './pages/About/History';
import Mission from './pages/About/Mission&Vision';
import Objective from './pages/About/Objective';
import Kulgeet from './pages/About/Kulgeet';

import Contact from './pages/Contact/Contact';
import CourseBed from './pages/Courses/BEd';
import CourseDelEd from './pages/Courses/DelEd';
import Undergraduate from './pages/Courses/Undergraduate';
import Postgraduate from './pages/Courses/Postgraduate';
import AdmissionProcedure from './pages/Admission/AdmissionProcedure';

import Message from './pages/Result/Message';
import ErrorMsg from './pages/Result/ErrorMsg';

import RulesofCollege from './pages/Admission/RulesofCollege';
import RulesofLibrary from './pages/Admission/RulesofLibrary';
import TalentSearchResult from './pages/Result/TalentSearch';
import Certificate from './pages/Result/Certificate';
import TalentSearchExamFrom from './pages/Result/TalentSearchExamFrom';
import TalentSearchExamEligibility from './pages/Result/TalentSearchExamEligibility';
import AdmitCard from './pages/Result/AdmitCard';
import KnowRegistration from './pages/Result/KnowRegistration';

import ManagerMsg from './pages/About/ManagerMsg';
import PrincipalMsg from './pages/About/PrincipalMsg';
import TimeTable from './pages/Student/TimeTable';
import Ncte from './pages/Ncte';
import AnnualReport from './pages/AnnualReport';
import Disciplinary from './pages/Disciplinary/Disciplinary';

import News from './pages/News/News';

function App() {
  return (
    <div>


      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/smartClass' element={<SmartClass />} />
        <Route path='/lab' element={<Lab />} />
        <Route path='/library' element={<Library />} />
        <Route path='/sports' element={<Sports />} />
        <Route path='/nss' element={<Nss />} />
        <Route path='/computer-center' element={<ComputerCenter />} />
        <Route path='/college-campus' element={<CollegeCampus />} />
        <Route path='/courses' element={<Courses />} />
        <Route path='/history' element={<History />} />
        <Route path='/manager-msg' element={<ManagerMsg />} />
        <Route path='/principal-msg' element={<PrincipalMsg />} />

        <Route path='/balancesheet2022' element={<Balancesheet />} />
        <Route path='/balancsheet2023' element={<Balancsheet />} />
        <Route path='/incomeexpenditure2023' element={<Income />} />
        <Route path='/receiptpayment2023' element={<Incomeex />} />
        <Route path='/incomeexpenditure2022' element={<Incomeexpenditure />} />
        <Route path='/receiptpayment2022' element={<Payment />} />

        <Route path='/feedback' element={<Feedback />} />
        <Route path='/complaints' element={<Complaints />} />
        <Route path='/faculty' element={<Faculty />} />
        <Route path='/bed' element={<Bed />} />
        <Route path='/deled' element={<Deled />} />
        <Route path='/samarthPortal' element={<SamarthPortal />} />
        <Route path='/student_data' element={<StudentData />} />
        <Route path='/attendence' element={<Biomatric />} />
        <Route path='/timetable' element={<TimeTable />} />
        <Route path='/ncteOrder' element={<Ncte />} />
        <Route path='/annualReport' element={<AnnualReport />} />

        <Route path='/swyamPrabha' element={<SwayamPrabha />} />
        <Route path='/gallery' element={<Gallery />} />

        <Route path='/course_bed' element={<CourseBed />} />
        <Route path='/course_deled' element={<CourseDelEd />} />
        <Route path='/undergraduate' element={<Undergraduate />} />
        <Route path='/postgraduate' element={<Postgraduate />} />

        <Route path='/admission-procedure' element={<AdmissionProcedure />} />
        <Route path='/college-rules-and-regulations' element={<RulesofCollege />} />
        <Route path='/library-rules-and-regulations' element={<RulesofLibrary />} />

        <Route path='/contact' element={<Contact />} />
        <Route path='/mission&vision' element={<Mission />} />
        <Route path='/objective' element={<Objective />} />
        <Route path='/kulgeet' element={<Kulgeet />} />

        <Route path='/talent-search-result-2025' element={<TalentSearchResult />} />
        <Route path='/certificate/:rollno' element={<Certificate />} />
        <Route path='/talent-search-exam-eligibility-2025' element={<TalentSearchExamEligibility />} />
        <Route path='/talent-search-exam-from-2025' element={<TalentSearchExamFrom />} />
        <Route path='/admit-card-2025' element={<AdmitCard />} />
        <Route path='/formSuccess/:id' element={<Message />} />
        <Route path='/news/:id' element={<News />} />
        <Route path='/formerror' element={<ErrorMsg />} />
        <Route path='/know-your-registration' element={<KnowRegistration />} />

        <Route path='/news' element={<News />} />

        <Route path='/disciplinary/' element={<Disciplinary />} />
      </Routes>


    </div>
  );
}

export default App;

import React, { useState } from "react";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";
import "./TalentSearch.css";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, Col, Row, Card, CardBody, Button, Table } from "react-bootstrap";

const schema = yup.object().shape({
    aadhar: yup.string(),

});

function KnowRegistration() {

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),

    })

    const [record, setRecord] = useState(null);
    const [error, setError] = useState('');
    const [showForm, setShowForm] = useState(false);

    const getExamDataByAadhar = async (data) => {
        await fetch("https://app.acmcollege.org/api/getExamDataByAadhar?aadhar=" + data.aadhar, {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(c => {

                if (c.result = "success") {
                    reset(formValues => ({
                        ...formValues,
                        aadhar: '',

                    }))
                    setRecord(c.data);
                    setError(false);
                    setShowForm(true);
                } else {
                    setShowForm(false);
                    setError('not');
                }

            });
    }

    return (

        <div className="student-page">
            <Header />
            <header className="height-75">
                <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
                    <h1 className="text-center fw-semibold"> Talent Search Exam 2025</h1>
                </div>
            </header>

            <div className="container my-5">
                <div className="row">
                    <div className="col-lg-12 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
                        <h2 className="mb-4 mb-lg-5" style={{ alignSelf: "center" }}>
                            Talent Search Exam 2025
                        </h2>

                    </div>
                    <Row>

                        <div  >
                            <div className="container">
                                <Row>
                                    <Card style={{ marginBottom: "14px" }}>
                                        <CardBody>

                                            <Form onSubmit={handleSubmit(getExamDataByAadhar)}>
                                                <Row>
                                                    <Col lg={9}>

                                                        <Form.Group as={Row} className="mb-4" controlId="formPlaintextInput">
                                                            <Form.Label column sm="2">
                                                                Adhar Number
                                                            </Form.Label>
                                                            <Col sm="10">
                                                                <Form.Control type="number" placeholder="aadhar" {...register("aadhar")} />
                                                            </Col>
                                                        </Form.Group>

                                                    </Col>
                                                    <Col>
                                                        <Button variant="primary" type="submit" className="mb-3" >
                                                            Search
                                                        </Button>
                                                    </Col>
                                                </Row>

                                            </Form>

                                            <Card >
                                                <Row >
                                                    <Col>
                                                        <Table bordered className="top">
                                                            <tbody>
                                                                <tr>
                                                                    <td >
                                                                        <b>Registration No : </b>
                                                                        {record && record.regno}
                                                                    </td>
                                                                    <td >
                                                                        <b>Roll No. : </b>
                                                                        {record && record.rollno}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <b>Candidate Name : </b>
                                                                        {record && record.stu_name}
                                                                    </td>
                                                                    <td>
                                                                        <b>Father’s Name : </b>
                                                                        {record && record.father_name}
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td>
                                                                        <b>Date of Birth : </b>
                                                                        {record && record.dob}
                                                                    </td>
                                                                    <td>
                                                                        <b>Gender : </b>
                                                                        {record && record.gender}
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td colSpan={2}><b>Exam Centre : </b>
                                                                        Acharya Chankya Mahavidyalya Mahmoodpur Semari Sultanpur UP 228142
                                                                    </td>
                                                                </tr>

                                                                <tr>
                                                                    <td colSpan={2}>
                                                                        <b>Name of School /College : </b>
                                                                        {record && record.school}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={2}>
                                                                        <b>Residential Address : </b>
                                                                        {record && record.address}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </Table>

                                                        {/* <Button type='button' variant="warning"
                                                            // onClick={handlePrint} 
                                                            style={{ float: 'right' }}>Print</Button> */}

                                                    </Col>
                                                </Row>

                                            </Card>

                                        </CardBody>
                                    </Card>
                                </Row>
                            </div>

                        </div>

                    </Row >

                </div >
            </div >
            <Footer />
        </div >
    );
}

export default KnowRegistration;

import React, { useState } from "react";
import "./AdmitCard.css";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";
import { Card, Row, Col, Button } from 'react-bootstrap';
import { useParams } from "react-router-dom";

function Message() {
    const { id } = useParams();
    console.log(id);
    return (
        <>
            <div className="student-page">
                <Header />
                <header className="height-75">
                    <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
                        <h1 className="text-center fw-semibold"> Complete Your Registration </h1>
                    </div>
                </header>

                <div className="container my-5">
                    <div className="row">
                        <div className="col-lg-12 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
                            <h2 className="mb-4 mb-lg-5" style={{ alignSelf: "center", marginTop: '20px' }}>
                                Form Successfully submitted
                            </h2>
                        </div>

                        <Row>

                            <div  >
                                <div className="container">

                                    <Row>
                                        <Col lg={2}>

                                        </Col>
                                        <Col lg={8}>

                                            <Card>
                                                <Card.Body>

                                                    <Card.Text>
                                                        Form Successfully submitted.
                                                        <p>Your registration no is
                                                            <b style={{ color: "red" }}> {id}</b>
                                                        </p>

                                                    </Card.Text>

                                                    <Card.Link href="/admit-card-2025">
                                                        Print Admit Card
                                                    </Card.Link>
                                                </Card.Body>
                                            </Card>

                                        </Col>
                                        <Col lg={2}></Col>

                                    </Row>
                                </div>

                            </div>

                        </Row >

                    </div>
                </div>
                <Footer />
            </div >

        </>
    );
}

export default Message;

import React from 'react'
import Header from '../../components/ChooseSection/Navbar/Header'
import Footer from '../../components/Footer/Footer'

function Sports() {
  return (
    <div>
      <Header/>
    <div className='container my-5'>
      <div className="row">
        <div className='col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'>
            <h2 className='mb-4 mb-lg-5'>Sports</h2>
            <p style={{textAlign:'justify'}}>Sport is an integral part of the curriculum. Various sports facility is provided to the students within the campus. The COLLEGE is committed to create a balanced atmosphere of academic, cultural and sports activities for the overall personality development of its students. Various sports competitions such as inter departmental, Inter collegiate, Inter University, etc help in developing team spirit in students. Sports and games help the students to improve their interpersonal relationship am in healthy manner. Talented students are honored with medals, trophies and certificates.
            Following facilities are available for Games and Sports: Basket Ball Courts, One Tennis Court, Two Volley Ball Courts, Two Badminton Courts, Badminton Courts, Tennis Tables , Long jump and High jump arenas.</p>
           
        </div>
        <div className='col-lg-6 d-flex justify-content-center'>
            <img src="./img/blog3-img.jpg" className='img-fluid w-100 h-75' alt="" />
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  )
}

export default Sports
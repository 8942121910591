import React from "react";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";

function PrincipalMsg() {
  return (
    <div className="about-page">
      <Header />
      <header className="height-75">
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
          <h1 className="text-center fw-semibold">Principal's Message</h1>
        </div>
      </header>

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
            <h2 className="mb-4 mb-lg-5">Principal's Message</h2>
            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
              Welcome to the Acharya Chanakya Mahavidyalaya,
              <br />
              As the Principal of Acharya Chanakya P. G. College. I am very
              fortunate to work with many dedicated, innovative and caring staff
              members, parents and students. Each day is filled with new
              experiences, learning for all, and the ability to make someone's
              day better; many days it is the students who make my day a little
              brighter.
              <br />
              We aim to provide excellence in all our education programs. Our
              staff consistently follows current trends and in collaboration
              with administration, creates a plan of action to incorporate those
              trends that are most appropriate into the classrooms.
              <br />
              The students at Acharya Chanakya P. G. College. are offered many
              opportunities to explore their interests and investigate new
              ideas. We offer many activities like sports, cultural programs,
              essay competition and co-curricular activities that our students
              can participate in. Many of our students are involved in a variety
              of extracurricular activities that allow them the opportunity to
              grow at their own pace in a safe, nurturing environment.
              <br />
              Acharya Chanakya P. G. College. has some outstanding facilities
              (e.g. well maintained campus, Grassed Playing Fields, Interactive
              whiteboards in every classroom, Digital projectors in teaching
              space, Computer Labs with high speed Internet access, Music Room
              with all types of musical Instruments, well equipped science
              laboratories, Multipurpose Hall, Badminton, Basket Ball and hand
              ball Courts, Separate Common Rooms for Girls and Boys.)
              <br />
              Each and every year on Basant Panchmi we celebrate annual function
              as a foundation day of the college. It is our belief that our
              children of today are our leaders of tomorrow and with that
              theory, our children are our future.
              <br />
              We are certain that you will find Acharya Chanakya P. G. College.
              to be an excellent choice of your education.
              <br />
              As the Principal my motto is{" "}
              <b>"Children must be taught how to think, not what to think,"</b>
              <br />
              As always please feel free to contact me with any question or
              concern.
            </p>
          </div>
          <div className="col-lg-6 d-flex justify-content-center">
            <img
              src="./img/principal.jpg"
              className="img-fluid h-25 w-50"
              alt=""
            />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default PrincipalMsg;

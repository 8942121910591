import React, { useEffect, useState } from 'react';
import { Card, Row, CardBody, CardHeader } from 'react-bootstrap';
import setting from "../../setting.json";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import DoubleArrow from '@mui/icons-material/ArrowForward';

function ChooseSection() {

  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [data, setData] = useState([]);
  const [newsCount, setNewsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const getAllNews = async () => {
    await fetch(setting.api + "api/getAllNews", {
      method: "GET",
      mode: "cors",
    })
      .then(response => response.json())
      .then(u => {
        setList(u.data);
      });
  }

  const view = () => {
    navigate("/News");
  }

  useEffect(() => {
    getAllNews();
  }, []);

  useEffect(() => {
    setNewsCount(list.length);
    setPageCount(Math.ceil(list.length / pageSize));
    var start = ((currentPage - 1) * pageSize);
    var end = (start + pageSize);
    setData(list.slice(start, end));
    setCurrentPage(1);
  }, [list]);

  useEffect(() => {

    var start = ((currentPage - 1) * pageSize);
    var end = (start + pageSize);
    setData(list.slice(start, end));

  }, [currentPage])


  return (
    <div>
      <div className="container">
        <div className="row g-4">
          <div className="col-lg-8">
            <Card className="d-flex align-items-center border-0 h-100">
              <Card.Body>
                <Card.Title className="text-center text-capitalize mb-3">
                  <h2
                    className="text-center mb-5 px-2 mx-0 "
                    style={{
                      borderBottom: "solid black 2px ",
                      fontWeight: "500",
                    }}
                  >
                    {" "}
                    Acharya Chanakya Mahavidyalaya{" "}
                  </h2>
                </Card.Title>
                <Card.Text className="text-center">
                  <p className="px-2 mx-0" style={{ textAlign: "justify" }}>
                    Acharya Chanakya Mahavidyalaya, is named on the great
                    Maharishi ACHARYA CHANKYA. Acharya chankya mahaviadyalya
                    Samiti ,semari sultanpur was established by Dr.Om Prakash
                    Tripathi the manager of the acharya chankya samiti in the
                    year of 2007. The college got affiliated to Dr.Ram Manohar
                    Lohia Avadh University , Faizabad in the Graduate and Post
                    Graduate Courses. Also the college has got affiliation in
                    two years B.Ed course. Some vocational courses also run like
                    D.El.ED (BTC) from Parkisha Niyamak Allhabad Uttar Pradesh.
                    And Diploma In Homoeopathic Pharmacy (DHP) affiliated by
                    Homoeopathic Medicine Board Lucknow, Uttar Pradesh.
                  </p>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>

          <div className="col-lg-4">
            <Card
              className="d-flex h-100"
              style={{
                border: "solid 2px #2f2f30",
                backgroundColor: "floralwhite"
              }}
            >

              <Card.Body>

                <CardHeader style={{ backgroundColor: "lightgray", color: "black", textAlign: "center" }}><b>News & Notification</b></CardHeader>
                {data && data.map((n) =>

                  <p className='text-start' style={{ borderBottom: "solid 1px", marginTop: "7px" }}>
                    <DoubleArrow />
                    {n.description}
                    <a href={n.description} target="_blank"><Button variant="danger" size="sm" style={{ padding: "unset", marginLeft: "4px" }}>new</Button></a>
                  </p>

                )}

                <Button variant="outline-primary" onClick={view} style={{ float: "right" }}>View All News</Button>

              </Card.Body>
            </Card>
          </div>
        </div >
      </div >
    </div >
  );
}

export default ChooseSection;
import React from "react";
import "./Admission.css";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";

function RulesofCollege() {
  return (
    <div className="admission-page">
      <Header/>
      <header className="height-75">
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
          <h1 className="text-center fw-semibold">RULES AND REGULATIONS</h1>
        </div>
      </header>

      <div className="container my-5">
        <div className="row">
          <div className="col-lg-6 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
            <h2 className="mb-4 mb-lg-5">RULES AND REGULATIONS FOR COLLEGE</h2>

            <p className="mb-4 mb-lg-5" style={{ textAlign: "justify" }}>
              <ul>
                <li>
                  The college gives utmost priority for discipline and every
                  one, student or staff, is bound to follow the rules and
                  regulations of the college and maintain strict discipline.
                </li>
                <li>
                  Under disciplinary action, the principal is empowered to fine,
                  suspend or even expel a student from the college in the
                  interests of the institution.
                </li>
                <li>
                  Students are not permitted to possess or use Mobile Phones
                  inside the Classroom during classes.
                </li>

                <li>
                  No one will be allowed to listen to Music from any device
                  inside the college campus.
                </li>

                <li>
                  RAGGING, CONSUMING ALCOHOL AND SMOKING ARE STRICTLY PROHIBITED
                  IN THE COLLEGE CAMPUS.
                </li>
                <li>
                  No function shall be arranged by the students in the college
                  campus without prior permission from the principal.
                </li>
                <li>
                  Writing on walls, pillars, bath rooms, furniture or black
                  boards is strictly prohibited.
                </li>
                <li>
                  The cost of any damage, if caused to college property will be
                  charged to the accounts of the students responsible for the
                  damage in addition to disciplinary action.
                </li>
                <li>
                  Students are advised to switch off fans and lights when they
                  leave the class rooms.
                </li>
                <li>
                  Furniture in the class rooms should not be moved or displaced.
                </li>
                <li>
                  Students are not allowed to attend classes or any college
                  function wearing shorts, dhotis, jeans or T-Shirts. or
                  sleeveless cloth
                </li>
                <li>All students should wear proper uniform.</li>
                <li>
                  Students must park their vehicles only in the space allotted.
                </li>

                <li>
                Plastic or other trash should not be thrown inside the college or in the premises.
                </li>
                <li>Students are required to keep safe custody of their valuables.</li>
                <li>
                Every student should carry his/ her identity card and produce it on demand by the authorities.
                </li>
                <li>
                Political/ organizational activities are banned in the campus, save those official activities which are specifically permitted or sponsored by the college authorities.
                </li>
                <li>
                Students are expected to attend all classes without fail. If, for unavoidable reasons, leave of absence is required, permission from competent authority should be sought.
                </li>
                <li>All students should reach the classroom on time and shall not leave the class without the permission of the teacher.</li>
                <li>The Principal shall be the final authority in the interpretation of the College rules. Matters not covered by these rules are left to the discretion of the Principal and his decision shall be final.</li>
                
              </ul>
            </p>
          </div>
          <div className="col-lg-6 d-flex justify-content-center">
            <img
              src="./img/history.jpg"
              className="img-fluid h-25 w-100"
              alt=""
            />
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default RulesofCollege;

import React from 'react'
import Header from '../../components/ChooseSection/Navbar/Header'
import Footer from '../../components/Footer/Footer'

function Library() {
  return (
    <div>
      <Header/>
    <div className='container my-5'>
      
      <div className="row">
        <div className='col-lg-6 d-flex flex-column justify-content-center mb-4 mb-lg-0'>
            <h2 className='mb-4 mb-lg-5'>Library</h2>
            <p>Library is the heart of any educational institution and in keeping with this view the college has a well furnished library having adequate books on all subjects along with reference books, encyclopedias, dictionaries, etc .The students also have an easy access to internet in the college where they can gain valuable latest information about their subjects. The students are provided with peaceful environment and are encouraged for self-study. The college has a central library holding sufficient number of books and periodicals/journals to meet the requirements of the faculty members and the students. The College Library is situated on the ground floor. It has a rich collection of nearly 10,000 books on various subjects. The Collection is continuously updated. It has a spacious and well-ventilated reading room for boys and girls. Normally working hours of the library and reading room are from 10 a.m. to 4 p.m.</p>
           
        </div>
        <div className='col-lg-6 d-flex justify-content-center'>
            <img src="./img/library-acm.jpg" className='img-fluid w-100 h-75' alt="" />
        </div>
      </div>
    </div>
    <Footer/>
    </div>
  )
}

export default Library
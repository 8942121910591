import React from 'react';
import './Courses.css';
import { Card } from 'react-bootstrap';
import Gallery2 from "../../images/gallery2.jpg";
import Gallery3 from "../../images/gallery3.jpg";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";
import { Link } from "react-router-dom";

function Courses() {
  return (
    <div className="courses-page">
      <Header />
      <header className="height-75">
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
          <h1 className="text-center fw-semibold">Our Courses</h1>
        </div>
      </header>

      <div className="container py-5">
        <div className="row g-4">
          <div className="col-lg-6">
            <Card className="text-white shadow">
              <Card.Img src={Gallery2} />
              <Card.ImgOverlay className="d-flex flex-column align-items-center justify-content-center p-md-3">
                <Card.Title>
                  <Link
                    to="/BEd"
                    className="fs-1 text-danger fw-semibold"
                    style={{ textDecoration: "none" }}
                  >
                    B.Ed.
                  </Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </div>
          <div className="col-lg-6">
            <Card className="text-white shadow">
              <Card.Img src={Gallery3} />
              <Card.ImgOverlay className="d-flex flex-column align-items-center justify-content-center p-md-3">
                <Card.Title className="fs-1 text-danger fw-semibold">
                  <Link
                    to="/DelEd"
                    className="fs-1 text-danger fw-semibold"
                    style={{ textDecoration: "none" }}
                  >
                    D.El.Ed.
                  </Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </div>
          <div className="col-lg-6">
            <Card className="text-white shadow">
              <Card.Img src={Gallery3} />
              <Card.ImgOverlay className="d-flex flex-column align-items-center justify-content-center p-md-3">
                <Card.Title className="fs-1 text-danger fw-semibold">
                  <Link
                    to="/undergraduate"
                    className="fs-1 text-danger fw-semibold"
                    style={{ textDecoration: "none" }}
                  >
                    Undergraduate Courses
                  </Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </div>
          <div className="col-lg-6">
            <Card className="text-white shadow">
              <Card.Img src={Gallery2} />
              <Card.ImgOverlay className="d-flex flex-column align-items-center justify-content-center p-md-3">
                <Card.Title className="fs-1 text-danger fw-semibold">
                  <Link
                    to="/postgraduate"
                    className="fs-1 text-danger fw-semibold"
                    style={{ textDecoration: "none" }}
                  >
                    Postgraduate Courses
                  </Link>
                </Card.Title>
              </Card.ImgOverlay>
            </Card>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Courses;
import React, { useState } from "react";
import "./AdmitCard.css";
import Header from "../../components/ChooseSection/Navbar/Header";
import Footer from "../../components/Footer/Footer";
import { Card, CardBody, Table, Row, Col, Container, Form, Button } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import logoImage from '../../images/logo.png';
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import setting from '../../setting.json';
const schema = yup.object().shape({
    regno: yup.string(),
});

function AdmitCard() {

    const contentRef = useRef();
    const reactToPrintFn = useReactToPrint({ contentRef });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    const [record, setRecord] = useState(null);
    const [show, setShow] = useState(' ')
    const [dob, setDob] = useState()

    const getExamDataByRegno = async (data) => {
        await fetch(setting.api + "/api/getExamDataByRegno?regno=" + data.regno, {
            method: "GET",
            mode: "cors",
        })
            .then(response => response.json())
            .then(c => {
                if (c.result = "success") {
                    var now = new Date(c.data.dob * 1000);
                    var month = now.getMonth() + 1;
                    setDob(now.getDate() + '-' + month + '-' + now.getFullYear());
                    reset(formValues => ({
                        ...formValues,
                        regno: '',
                    }))
                    setRecord(c.data);
                }
            });
    }


    return (
        <>
            <div className="student-page">
                <Header />
                <header className="height-75">
                    <div className="container h-100 d-flex flex-column align-items-center justify-content-center text-light">
                        <h1 className="text-center fw-semibold"> Talent Search Exam 2025 </h1>
                    </div>
                </header>

                <div className="container my-5">
                    <div className="row">
                        <div className="col-lg-12 d-flex flex-column text-align-justify justify-content-center mb-4 mb-lg-0">
                            <h2 className="mb-4 mb-lg-5" style={{ alignSelf: "center", marginTop: '20px' }}>
                                Download Your Admit Card
                            </h2>
                        </div>

                        <Row>

                            <div  >
                                <div className="container">
                                    <Row>
                                        <Card style={{ marginBottom: "14px" }}>
                                            <CardBody>

                                                <Form onSubmit={handleSubmit(getExamDataByRegno)}>
                                                    <Row>
                                                        <Col lg={9}>

                                                            <Form.Group as={Row} className="mb-4" controlId="formPlaintextInput">
                                                                <Form.Label column sm="2">
                                                                    Registration Number.
                                                                </Form.Label>
                                                                <Col sm="10">
                                                                    <Form.Control type="text" placeholder="registration no." {...register("regno")} />
                                                                </Col>
                                                            </Form.Group>

                                                        </Col>
                                                        <Col>
                                                            <Button variant="primary" type="submit" className="mb-3" onClick={() => setShow('add')}>
                                                                Search
                                                            </Button>
                                                        </Col>

                                                    </Row>

                                                </Form>

                                            </CardBody>
                                        </Card>
                                    </Row>
                                </div>

                            </div>

                        </Row >

                        {show == 'add' &&
                            <div>
                                <Row>
                                    <Col>
                                        <Button variant="success" onClick={() => reactToPrintFn()} style={{ float: 'right' }}>Print</Button>
                                    </Col>
                                </Row>

                                <Container fluid ref={contentRef}>

                                    <Row>
                                        <Card style={{ marginBottom: "14px" }}>

                                            <CardBody>

                                                <Table bordered >
                                                    <thead>
                                                        <tr>
                                                            <th style={{ textAlign: 'center' }}>
                                                                <img src={logoImage} width="80px" height="80px" />
                                                            </th>
                                                            <th style={{ textAlign: "center" }}>
                                                                <p style={{ marginBottom: '0px' }}>
                                                                    <b>आचार्य चाणक्य महाविद्यालय महमूदपुर सेमरी सुल्तानपुर </b>
                                                                </p>
                                                                <p style={{ marginBottom: '0px' }}>Talent Search Examination - Exam Year 2025 </p>
                                                                <p style={{ marginBottom: '0px' }}>
                                                                    <b>Admit Card</b>
                                                                </p>

                                                            </th>
                                                        </tr>
                                                    </thead>
                                                </Table>
                                                <Card >
                                                    <Row >
                                                        <Col>

                                                            <Table bordered>

                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <b>Registration No :</b>
                                                                        </td>
                                                                        <td>
                                                                            {record && record.regno}
                                                                        </td>
                                                                        <td ><b>Roll No. : </b></td>
                                                                        <td > {record && record.rollno}</td>

                                                                        <td rowspan="3">

                                                                            <Image src={record && record.photo} style={{ width: "100px", height: "120px" }} />

                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            <b>Candidate Name :</b>
                                                                        </td>
                                                                        <td>
                                                                            {record && record.stu_name.toUpperCase()}
                                                                        </td>
                                                                        <td>
                                                                            <b>Father’s Name :</b>
                                                                        </td>
                                                                        <td>
                                                                            {record && record.father_name.toUpperCase()}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td>
                                                                            <b>Date of Birth :</b>
                                                                        </td>
                                                                        <td>
                                                                            {record && dob}
                                                                        </td>
                                                                        <td>
                                                                            <b>Gender :</b>
                                                                        </td>
                                                                        <td>
                                                                            {record && record.gender.toUpperCase()}
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td><b>Exam Center:</b></td>
                                                                        <td colSpan={4}>
                                                                            ACHARYA CHANKYA MAHAVIDYALYA MAHMOODPUR SEMARI SULTANPUR UP 228142
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td> <b>Name of School /College :</b></td>
                                                                        <td colSpan={4}>
                                                                            {record && record.school.toUpperCase()}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td >
                                                                            <b>Residential Address :</b>
                                                                        </td>
                                                                        <td colSpan={4}>
                                                                            {record && record.address.toUpperCase()}
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan={2}>
                                                                            <b>Date : 12-01-2025</b>
                                                                        </td>
                                                                        <td colspan={3}>
                                                                            <b>Time : 11:00AM to 01:00PM</b>
                                                                        </td>
                                                                    </tr>

                                                                    <tr>
                                                                        <td colspan={2} style={{ textAlign: 'center' }}>
                                                                            <p style={{ width: '100%', height: "50px", borderWidth: 1, borderColor: 'lightgray', borderStyle: 'solid', borderRadius: '5px' }}>&nbsp;
                                                                                {/* <Image src={record && record.sign} /> */}
                                                                            </p>
                                                                            <span>Candidate Signature</span>
                                                                        </td>

                                                                        <td colSpan={3} style={{ textAlign: 'center' }}>
                                                                            <p style={{ width: '100%', height: "50px", borderWidth: 1, borderColor: 'lightgray', borderStyle: 'solid', borderRadius: '5px' }}>&nbsp;</p>
                                                                            <span >Invigilator Signature </span>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colspan={5}>
                                                                            <h6 style={{ textAlign: "center" }}>
                                                                                --: INSTRUCTIONS FOR THE CANDIDATE: --
                                                                            </h6>
                                                                            <ul style={{ fontSize: "12px" }}>
                                                                                <li>Reporting Time 09:30 AM to 10:30 AM</li>
                                                                                <li>Candidates are advised to bring an Identity card such as Adhar Card / School Identity Card.</li>
                                                                                <li>Candidates have to use Black Ball Point Pen/Blue Ball Point Pen for Writing the Answer Sheet.</li>
                                                                                <li>No cell phones or any electronic device will be allowed in the examination hall. If any such items are found in possession of the candidate, they will be confiscated.</li>
                                                                                <li>No Candidate will be allowed to appear in the Examination without valid Admit Card.</li>
                                                                                <li>Candidates must return their Answer Book to the invigilator before leaving the Examination Hall.</li>
                                                                                <li>Do not write your name in any part of the question booklet or on answer sheet.</li>
                                                                            </ul>

                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </Table>
                                                        </Col>
                                                    </Row>

                                                </Card>

                                            </CardBody>
                                        </Card>
                                    </Row >
                                </Container >
                            </div>

                        }

                    </div>
                </div>
                <Footer />
            </div >

        </>
    );
}

export default AdmitCard;
